import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useValidateQueryParams } from "query_parameters/paramsHelper";
import { numToRoleMapping, ROLES } from "lib/role_helper";
import { useQueryString } from "hooks/general-hooks";
import { useJSONFetcher } from "@outplayed/json-fetcher";

import { validParams } from "query_parameters/valid_parameters";
import { buildQueryParams, parseQueryString } from "lib/general-helper.js";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getQueryParams, getForcedParams, getQueueTypeNameFromQueryParam } from "lib/specialGameMode";
import { alphanumeric } from "lib/general-helper.js";

// API Data parsers
import { getOverview, cleanOverviewData, cleanOverviewData2 } from "api/data_parser/champion_overview.js";
import { cleanRankingsData } from "api/data_parser/ranking_stats.js";
import { cleanCounters } from "api/data_parser/counters.js";
import { cleanMatchups } from "api/data_parser/matchups.js";
import { cleanItems } from "api/data_parser/items-table.js";
import { cleanRunes } from "api/data_parser/runes-table.js";
import { cleanDuosData } from "api/data_parser/champion_duos.js";
import { getRankings } from "api/data_parser/ranking_stats.js";

import {
  useChampionRolesURL,
  useChampionRankingStatsURL,
  useChampionOverviewURL,
  useAPChampionOverviewURL,
  useTankChampionOverviewURL,
  useADChampionOverviewURL,
  useCritChampionOverviewURL,
  useLethalityChampionOverviewURL,
  useOnHitChampionOverviewURL,
  useCTChampionOverviewURL,
  useChampionRecBuildURL,
  useItemsURL,
  useRunesURL,
  useMatchupsURL,
  useChampionDuosURL,
  useProHomepageURL,
} from "api/championProfileApi";

import { getKey } from "api/data_parser/dataParserHelpers";
import {
  OVERVIEW_EMERALD_PLUS_WORLD,
  OVERVIEW_EMERALD_PLUS_WORLD_AP,
  OVERVIEW_EMERALD_PLUS_WORLD_TANK,
  OVERVIEW_EMERALD_PLUS_WORLD_AD,
  OVERVIEW_EMERALD_PLUS_WORLD_CRIT,
  OVERVIEW_EMERALD_PLUS_WORLD_LETHALITY,
  OVERVIEW_EMERALD_PLUS_WORLD_ONHIT,
  OVERVIEW_EMERALD_PLUS_WORLD_CT,
  RANKINGS_EMERALD_PLUS_WORLD,
} from "@ugg/shared/constants/constants";

export function useValidatedChampionProfileParams(championId, page, { keepDefaultParams = false, ssr } = {}) {
  const { useBackupChampions } = getRiotAssetsContext();
  const backupChampions = useBackupChampions();
  const location = useLocation();
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams();

  const { data: primaryRoles } = usePrimaryRoles({ ssr });
  const validatedQueryParams = validateQueryParams({ queryParams, page, keepDefaultParams });
  const validPageParams = validParams[page];
  const oldParams = location.search.charAt(0) === "?" ? location.search.substring(1) : location.search;

  if (
    !keepDefaultParams &&
    validatedQueryParams.queueType &&
    validatedQueryParams.queueType !== "ranked_solo_5x5" &&
    validatedQueryParams.queueType !== "ranked_flex_sr"
  ) {
    delete validatedQueryParams.opp;
  }

  const isNexusBlitz = validatedQueryParams.queueType === "nexus_blitz" || page === "overview_nexus_blitz";
  // Remove default role param
  if ((!isNexusBlitz && !keepDefaultParams && !location.state) || (location.state && !location.state.persistRole)) {
    const primaryRole =
      primaryRoles && championId in primaryRoles
        ? numToRoleMapping(primaryRoles[championId][0])
        : backupChampions[championId] && backupChampions[championId].primaryRole
        ? backupChampions[championId].primaryRole
        : "middle";
    const defaultRole = validPageParams && validPageParams.default && validPageParams.default.role;
    if ((!defaultRole && validatedQueryParams.role === primaryRole) || primaryRole === defaultRole) {
      delete validatedQueryParams.role;
    }
  }

  const newParams = buildQueryParams(validatedQueryParams);
  const paramsToReturn = oldParams !== newParams ? newParams : oldParams;

  return useMemo(() => {
    return {
      pathname: location.pathname,
      search: paramsToReturn,
      validatedParams: parseQueryString(paramsToReturn),
    };
  }, [location.pathname, oldParams, newParams]);
}

export function usePrimaryRoles({ skip, ssr } = {}) {
  const primaryRolesURL = useChampionRolesURL();
  return useJSONFetcher(primaryRolesURL, {
    skip: skip || !primaryRolesURL,
    ssr,
  });
}

export function useNexusBlitzPrimaryRole(championId, options) {
  const { forcedParams, ssr, skip } = options || {};
  const params = { forcedParams: { ...forcedParams, queueType: "nexus_blitz", region: "world" }, ssr, skip };
  const { data, loading, error, params: overviewParams } = useChampionOverview(championId, params);

  const { queueType, region, rank } = overviewParams;
  const roleMatchCount = { [ROLES.LANE]: 0, [ROLES.JUNGLE]: 0 };
  const roles = Object.keys(roleMatchCount);
  roles.forEach((role) => {
    const { matches } = getOverview(data, { queueType, region, rank, role }) || {};
    roleMatchCount[role] = matches || 0;
  });
  let primaryRole = Object.entries(roleMatchCount).sort((a, b) => b[1] - a[1])[0][0];

  return { data: data ? primaryRole : null, loading, error };
}

const champion_transform_map = {
  141: [100141, 200141],
};

export function useMultipleChampionOverview(championId, { forcedParams, ssr, queryParams } = {}) {
  const res = {};
  const maxBuilds = Object.values(champion_transform_map).reduce((acc, curr) => {
    return Math.max(curr.length, acc);
  }, 1);
  const baseArray = Array(maxBuilds).fill(0);
  const champions = champion_transform_map[championId]
    ? champion_transform_map[championId].concat(baseArray.slice(champion_transform_map[championId].length))
    : [championId].concat(baseArray.slice(1));

  champions.map((championId) => {
    //Get champion transform from new location
    let {
      data: championOverview,
      loading: fetchingChampionOverview,
      error: errorChampionOverview,
    } = championId > 10000
      ? useCTChampionOverview(championId, { ssr: true, forcedParams, skip: !queryParams.opp })
      : useChampionOverview(championId, { ssr: true, forcedParams, skip: !queryParams.opp });

    let {
      data: apChampionOverview,
      loading: fetchingAPChampionOverview,
      error: errorAPChampionOverview,
    } = useAPChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    let {
      data: tankChampionOverview,
      loading: fetchingTankChampionOverview,
      error: errorTankChampionOverview,
    } = useTankChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    let {
      data: adChampionOverview,
      loading: fetchingADChampionOverview,
      error: errorADChampionOverview,
    } = useADChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    let {
      data: critChampionOverview,
      loading: fetchingCritChampionOverview,
      error: errorCritChampionOverview,
    } = useCritChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    let {
      data: lethalityChampionOverview,
      loading: fetchingLethalityChampionOverview,
      error: errorLethalityChampionOverview,
    } = useLethalityChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    let {
      data: onHitChampionOverview,
      loading: fetchingOnHitChampionOverview,
      error: errorOnHitChampionOverview,
    } = useOnHitChampionOverview(championId % 10000, { ssr: true, forcedParams, skip: queryParams.opp });

    const forcedDefaultParams = { ...forcedParams, opp: "allChampions" };
    const forceDefaultData = !(queryParams.opp && championOverview && !fetchingChampionOverview);
    const {
      data: defaultChampionOverview,
      loading: fetchingDefaultChampionOverview,
      error: errorDefaultChampionOverview,
    } = championId > 10000
      ? useCTChampionOverview(championId, { ssr: true, forcedParams: forcedDefaultParams, skip: !forceDefaultData })
      : useChampionOverview(championId, { ssr: true, forcedParams: forcedDefaultParams, skip: !forceDefaultData });

    if (forceDefaultData) {
      championOverview = defaultChampionOverview;
      fetchingChampionOverview = fetchingDefaultChampionOverview;
      errorChampionOverview = errorDefaultChampionOverview;
    }
    res[championId] = {
      forceDefaultData: forceDefaultData,
      championOverview: championOverview,
      apChampionOverview: apChampionOverview,
      tankChampionOverview: tankChampionOverview,
      adChampionOverview: adChampionOverview,
      critChampionOverview: critChampionOverview,
      lethalityChampionOverview: lethalityChampionOverview,
      onHitChampionOverview: onHitChampionOverview,
      fetchingChampionOverview:
        fetchingChampionOverview ||
        fetchingAPChampionOverview ||
        fetchingTankChampionOverview ||
        fetchingADChampionOverview ||
        fetchingCritChampionOverview ||
        fetchingLethalityChampionOverview ||
        fetchingOnHitChampionOverview,
      errorChampionOverview:
        errorChampionOverview ||
        errorAPChampionOverview ||
        errorTankChampionOverview ||
        errorADChampionOverview ||
        errorCritChampionOverview ||
        errorLethalityChampionOverview ||
        errorOnHitChampionOverview,
    };
  });

  return res;
}

export function useCTChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useCTChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });
  //console.log(championOverviewURL)
  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_CT}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip || !primaryRoles,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      //console.log(json)
      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_CT)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useAPChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useAPChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_AP}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_AP)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useTankChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useTankChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_TANK}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_TANK)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useADChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useADChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_AD}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_AD)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useCritChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useCritChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_CRIT}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_CRIT)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useLethalityChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useLethalityChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_LETHALITY}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_LETHALITY)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useOnHitChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useOnHitChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });

  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD_ONHIT}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);

      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD_ONHIT)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useChampionOverview(championId, { forcedParams, ssr, skip } = {}) {
  const { getChampionIdByName } = getRiotAssetsContext();
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true, ssr });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const championOverviewURL = useChampionOverviewURL(championId, {
    ...params,
    opp: getChampionIdByName(params.opp),
  });
  const customCacheKey = `${OVERVIEW_EMERALD_PLUS_WORLD}::${championOverviewURL}`;
  const fetchState = useJSONFetcher(championOverviewURL, {
    ssr,
    skip: skip || !primaryRoles,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanOverviewData(json);
      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(OVERVIEW_EMERALD_PLUS_WORLD)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading, params };
}

export function useMultipleChampionRankingStats(championId, { forcedParams, ssr, queryParams } = {}) {
  const res = {};
  const maxBuilds = Object.values(champion_transform_map).reduce((acc, curr) => {
    return Math.max(curr.length, acc);
  }, 1);
  const baseArray = Array(maxBuilds).fill(0);
  const champions = champion_transform_map[championId]
    ? champion_transform_map[championId].concat(baseArray.slice(champion_transform_map[championId].length))
    : [championId].concat(baseArray.slice(1));

  champions.map((championId) => {
    let {
      data: rankingStats,
      loading: fetchingRankingStats,
      error: errorRankingStats,
    } = useChampionRankingStats(championId, { ssr: true, forcedParams });

    res[championId] = {
      rankingStats: rankingStats,
      fetchingRankingStats: fetchingRankingStats,
      errorRankingStats: errorRankingStats,
    };
  });

  return res;
}

export function useChampionRankingStats(championId, { forcedParams, ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });
  const params = { ...validatedParams, ...forcedParams };

  const rankingStatsURL = useChampionRankingStatsURL(championId, params);
  //console.log(rankingStatsURL)
  const customCacheKey = `${RANKINGS_EMERALD_PLUS_WORLD}::${rankingStatsURL}`;
  const fetchState = useJSONFetcher(rankingStatsURL, {
    ssr,
    skip: !primaryRoles,
    customCacheKey:
      params.region === "world" && params.rank === "emerald_plus" && params.queueType === "ranked_solo_5x5" ? customCacheKey : "",
    onCompleted: (url, json, cacheKey) => {
      const cleanData = cleanRankingsData(json);
      const dataKey = getKey(params.region, params.rank, "");

      if (cacheKey.match(RANKINGS_EMERALD_PLUS_WORLD)) {
        return Object.fromEntries(Object.entries(cleanData || {}).filter(([key, value]) => key.match(dataKey)));
      }

      return cleanData;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

// Includes Builds Paths, Rune Sets, and Spells/Abilities
export function useChampionRecBuild(championId, page, { ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, page, { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championRecBuildURL = useChampionRecBuildURL(championId, validatedParams);
  const fetchState = useJSONFetcher(championRecBuildURL, {
    ssr,
    skip: !primaryRoles,
    onCompleted: (url, json) => cleanOverviewData2(json),
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

// Currently not being used
// export function useChampionCounters(championId) {
//   const { validatedParams } = useValidatedChampionProfileParams(championId, "counters", {keepDefaultParams: true});
//   const {data: primaryRoles, loading: loadingPrimaryRoles} = usePrimaryRoles();
//
//   const championCountersURL = useCountersURL(championId, validatedParams);
//   return useJSONFetcher(championCountersURL, {
//     skip: !primaryRoles,
//     onCompleted: (url, json) => cleanCounters(json)
//   })
//
//   return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
// }

// Counters and Matchups Page
export function useChampionMatchups(championId, { ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, "matchups", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championMatchupsURL = useMatchupsURL(championId, validatedParams);
  const fetchState = useJSONFetcher(championMatchupsURL, {
    ssr,
    skip: !primaryRoles,
    onCompleted: (url, json) => {
      const data = cleanMatchups(json) || {};
      return data.matchups;
    },
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useChampionItems(championId, { ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, "items", { keepDefaultParams: true, ssr: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championItemsURL = useItemsURL(championId, validatedParams);
  const fetchState = useJSONFetcher(championItemsURL, {
    ssr,
    skip: !primaryRoles,
    onCompleted: (url, json) => cleanItems(json),
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useChampionRunes(championId, { ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, "runes-table", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championRunesURL = useRunesURL(championId, validatedParams);
  const fetchState = useJSONFetcher(championRunesURL, {
    ssr,
    skip: !primaryRoles,
    onCompleted: (url, json) => cleanRunes(json),
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

export function useChampionDuos(championId, { ssr } = {}) {
  const { validatedParams } = useValidatedChampionProfileParams(championId, "duos", { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championDuosURL = useChampionDuosURL(championId, validatedParams);
  const fetchState = useJSONFetcher(championDuosURL, {
    ssr,
    skip: !primaryRoles,
    onCompleted: (url, json) => cleanDuosData(json),
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}

/******************************
 * Other Hooks
 ******************************/

export function useCurrentRole(championId, page, { ssr } = {}) {
  const { useBackupChampions } = getRiotAssetsContext();
  const backupChampions = useBackupChampions();
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams();
  const validatedQueryParams = validateQueryParams({ queryParams, page });
  const { data: primaryRoles } = usePrimaryRoles({ ssr });

  if (validatedQueryParams.role) {
    return validatedQueryParams.role;
  }

  if (!primaryRoles) {
    return undefined;
  } else {
    const primaryRole =
      championId in primaryRoles
        ? numToRoleMapping(primaryRoles[championId][0])
        : backupChampions[championId] && backupChampions[championId].primaryRole
        ? backupChampions[championId].primaryRole
        : "middle";

    return primaryRole;
  }
}

export function useCurrentQueueType(page) {
  const queryParams = useQueryString();
  const validateQueryParams = useValidateQueryParams();
  const validatedQueryParams = validateQueryParams({ queryParams, page });
  const location = useLocation();

  if (location.pathname.includes("build/aram")) {
    // Search for path build/aram
    return "normal_aram";
  }

  if (location.pathname.includes("build/arurf")) {
    // Search for path build/aram
    return "arurf";
  }

  if ("queueType" in validatedQueryParams) {
    return validatedQueryParams["queueType"];
  }

  return "ranked_solo_5x5";
}

export function useAramUrfData(queueType, version) {
  const { getWorkingPatch } = getRiotAssetsContext();
  const latestPatch = getWorkingPatch().split(".").slice(0, -1);
  let selectedPatch = version.split("_");
  let patch = null;

  if (latestPatch[0] === "12") {
    if (parseInt(selectedPatch[1]) < 10) {
      patch = "12_10";
    } else {
      patch = version;
      if (parseInt(latestPatch[1]) < parseInt(selectedPatch[1])) {
        patch = latestPatch.join("_");
      }
    }
  } else {
    patch = version;
    if (parseInt(latestPatch[1]) < parseInt(selectedPatch[1])) {
      patch = latestPatch.join("_");
    }
  }

  const {
    data: allChampionStatsARAM,
    loading: status1,
    error: errARAM,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/queue_type_champion_changes/aram/${patch}.json`, {
    ssr: true,
    skip: queueType !== "normal_aram",
  });
  const {
    data: allChampionStatsURF,
    loading: status2,
    error: errURF,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/queue_type_champion_changes/urf/${patch}.json`, {
    ssr: true,
    skip: queueType !== "urf" && queueType !== "arurf",
  });

  return { allChampionStatsARAM, allChampionStatsURF };
}

export function useTabData(currentRole) {
  const role = currentRole;
  const champion_transform_map = {
    141: [100141, 200141],
  };

  function getDefaultChampionTransformId(championId) {
    return champion_transform_map[championId] ? champion_transform_map[championId][0] : championId;
  }

  function isChampionTransform(championId) {
    return championId > 10000;
  }

  function shouldEnableAP(filteredOverview, filteredAPOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const apKeyItem = filteredAPOverview?.rec_core_items?.items
      ? getClassifyItem(filteredAPOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableAP =
      filteredAPOverview &&
      defaultKeyItem &&
      apKeyItem &&
      !(apKeyItem === defaultKeyItem) &&
      filteredAPOverview?.item_options_1?.length > 1 &&
      filteredAPOverview?.item_options_2?.length > 1 &&
      filteredAPOverview?.item_options_3?.length > 1 &&
      filteredAPOverview?.rec_starting_items?.matches > 9 &&
      filteredAPOverview?.rec_core_items?.matches > 9;
    return shouldEnableAP;
  }

  function shouldEnableTank(filteredOverview, filteredTankOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const tankKeyItem = filteredTankOverview?.rec_core_items?.items
      ? getClassifyItem(filteredTankOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableTank =
      filteredTankOverview &&
      defaultKeyItem &&
      tankKeyItem &&
      !(tankKeyItem === defaultKeyItem) &&
      filteredTankOverview?.item_options_1?.length > 1 &&
      filteredTankOverview?.item_options_2?.length > 1 &&
      filteredTankOverview?.item_options_3?.length > 1 &&
      filteredTankOverview?.rec_starting_items?.matches > 9 &&
      filteredTankOverview?.rec_core_items?.matches > 9;

    return shouldEnableTank;
  }

  function shouldEnableAD(filteredOverview, filteredADOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const adKeyItem = filteredADOverview?.rec_core_items?.items
      ? getClassifyItem(filteredADOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableAD =
      filteredADOverview &&
      defaultKeyItem &&
      adKeyItem &&
      !(adKeyItem === defaultKeyItem) &&
      filteredADOverview?.item_options_1?.length > 1 &&
      filteredADOverview?.item_options_2?.length > 1 &&
      filteredADOverview?.item_options_3?.length > 1 &&
      filteredADOverview?.rec_starting_items?.matches > 9 &&
      filteredADOverview?.rec_core_items?.matches > 9 &&
      filteredADOverview?.rec_core;

    return shouldEnableAD;
  }

  function shouldEnableCrit(filteredOverview, filteredCritOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const critKeyItem = filteredCritOverview?.rec_core_items?.items
      ? getClassifyItem(filteredCritOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableCrit =
      filteredCritOverview &&
      defaultKeyItem &&
      critKeyItem &&
      !(critKeyItem === defaultKeyItem) &&
      filteredCritOverview?.item_options_1?.length > 1 &&
      filteredCritOverview?.item_options_2?.length > 1 &&
      filteredCritOverview?.item_options_3?.length > 1 &&
      filteredCritOverview?.rec_starting_items?.matches > 9 &&
      filteredCritOverview?.rec_core_items?.matches > 9;

    return shouldEnableCrit;
  }

  function shouldEnableLethality(filteredOverview, filteredLethalityOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const lethalityKeyItem = filteredLethalityOverview?.rec_core_items?.items
      ? getClassifyItem(filteredLethalityOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableLethality =
      filteredLethalityOverview &&
      defaultKeyItem &&
      lethalityKeyItem &&
      !(lethalityKeyItem === defaultKeyItem) &&
      filteredLethalityOverview?.item_options_1?.length > 1 &&
      filteredLethalityOverview?.item_options_2?.length > 1 &&
      filteredLethalityOverview?.item_options_3?.length > 1 &&
      filteredLethalityOverview?.rec_starting_items?.matches > 9 &&
      filteredLethalityOverview?.rec_core_items?.matches > 9;

    return shouldEnableLethality;
  }

  function shouldEnableOnHit(filteredOverview, filteredOnHitOverview, mythicItems, bootItems) {
    const defaultKeyItem = filteredOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const onHitKeyItem = filteredOnHitOverview?.rec_core_items?.items
      ? getClassifyItem(filteredOnHitOverview?.rec_core_items?.items, mythicItems, bootItems)
      : 0;
    const shouldEnableOnHit =
      filteredOnHitOverview &&
      defaultKeyItem &&
      onHitKeyItem &&
      !(onHitKeyItem === defaultKeyItem) &&
      filteredOnHitOverview?.item_options_1?.length > 1 &&
      filteredOnHitOverview?.item_options_2?.length > 1 &&
      filteredOnHitOverview?.item_options_3?.length > 1 &&
      filteredOnHitOverview?.rec_starting_items?.matches > 9 &&
      filteredOnHitOverview?.rec_core_items?.matches > 9;

    return shouldEnableOnHit;
  }

  function getClassifyItem(items, mythicItems, bootItems) {
    if (!items || !mythicItems || !bootItems) {
      return 0;
    }
    let mythicFilter = items.filter((item) => mythicItems.includes(item));
    if (mythicFilter.length) {
      return mythicFilter[0];
    }
    var normal = 0;
    var others = 0;
    for (let i = 0; i < items.length; i++) {
      if (mythicItems.includes(items[i])) {
        return items[i];
      } else if (!bootItems.includes(items[i]) && normal === 0) {
        normal = items[i];
      } else {
        others = items[i];
      }
    }
    return normal != 0 ? normal : others;
  }

  //useparams from react router to get the value of multi
  const urlParams = useParams();
  const location = useLocation();
  const { getChampionIdByName } = getRiotAssetsContext();
  const champName = urlParams.champion;
  const normalizedChampionName = alphanumeric(champName.split("-")[0]);
  const championId = getChampionIdByName(normalizedChampionName);

  let queryParams = useQueryString();
  let forcedParams = {};

  // Special Game Mode Handling
  if (getQueryParams(location.pathname, queryParams)) {
    queryParams = getQueryParams(location.pathname, queryParams);
  }

  if (getForcedParams(location)) {
    forcedParams = getForcedParams(location);
  }

  const { useRiotMythicItems, useRiotBootItems } = getRiotAssetsContext();
  const { data: mythicItems } = useRiotMythicItems({ ssr: true });
  const { data: bootItems } = useRiotBootItems({ ssr: true });

  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true, ssr: true });

  const params = {
    ...validatedParams,
    ...queryParams,
    ...forcedParams,
    role,
  };

  const championOverviews = useMultipleChampionOverview(championId, { ssr: true, forcedParams, queryParams: queryParams });
  //Add other builds here as we go
  const isAP = urlParams.multi === "ap" ? true : false;
  const isTank = urlParams.multi === "tank" ? true : false;
  const isAD = urlParams.multi === "ad" ? true : false;
  const isCrit = urlParams.multi === "crit" ? true : false;
  const isLethality = urlParams.multi === "lethality" ? true : false;
  const isOnHit = urlParams.multi === "onhit" ? true : false;

  const tracking = isChampionTransform(getDefaultChampionTransformId(championId))
    ? [
        championOverviews[champion_transform_map[championId][0]].fetchingChampionOverview,
        championOverviews[champion_transform_map[championId][1]].fetchingChampionOverview,
      ]
    : [championOverviews[championId].fetchingChampionOverview];

  const firstLoad = useRef(true);
  const { buildKey, tabData } = useMemo(() => {
    let buildKey = getDefaultChampionTransformId(championId);
    const tabData = {};
    const transformChampionId = getDefaultChampionTransformId(championId);
    if (isChampionTransform(transformChampionId)) {
      let maxWinRate = 0;
      for (const [key, value] of Object.entries(championOverviews)) {
        const filteredOverview = getOverview(value.championOverview, params);
        tabData[key] = [
          filteredOverview?.rec_runes?.active_perks[0],
          filteredOverview?.rec_core_items?.items
            ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
        ];
        if (filteredOverview && filteredOverview.win_rate > maxWinRate && firstLoad.current) {
          buildKey = key;
          maxWinRate = filteredOverview.win_rate;
        }
      }
      firstLoad.current = false;
    } else {
      const filteredOverview = getOverview(championOverviews[transformChampionId]?.championOverview, params);
      tabData["default"] = [
        filteredOverview?.rec_runes?.active_perks[0],
        filteredOverview?.rec_core_items?.items
          ? getClassifyItem(filteredOverview?.rec_core_items?.items, mythicItems, bootItems)
          : 0,
        100000,
      ];
      const filteredAPOverview = getOverview(championOverviews[transformChampionId]?.apChampionOverview, params);
      const enableAP = shouldEnableAP(filteredOverview, filteredAPOverview, mythicItems, bootItems, isAP);
      if (filteredAPOverview && filteredOverview && enableAP) {
        tabData["ap"] = [
          filteredAPOverview?.rec_runes?.active_perks[0],
          filteredAPOverview?.rec_core_items?.items
            ? getClassifyItem(filteredAPOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredAPOverview.matches ? filteredAPOverview.matches : 0,
        ];
      }
      const filteredTankOverview = getOverview(championOverviews[transformChampionId]?.tankChampionOverview, params);
      const enableTank = shouldEnableTank(filteredOverview, filteredTankOverview, mythicItems, bootItems, isTank);
      if (filteredTankOverview && filteredOverview && enableTank) {
        tabData["tank"] = [
          filteredTankOverview?.rec_runes?.active_perks[0],
          filteredTankOverview?.rec_core_items?.items
            ? getClassifyItem(filteredTankOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredTankOverview.matches ? filteredTankOverview.matches : 0,
        ];
      }
      const filteredADOverview = getOverview(championOverviews[transformChampionId]?.adChampionOverview, params);
      const enableAD = shouldEnableAD(filteredOverview, filteredADOverview, mythicItems, bootItems, isAD);
      if (filteredADOverview && filteredOverview && enableAD) {
        tabData["ad"] = [
          filteredADOverview?.rec_runes?.active_perks[0],
          filteredADOverview?.rec_core_items?.items
            ? getClassifyItem(filteredADOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredADOverview.matches ? filteredADOverview.matches : 0,
        ];
      }
      const filteredCritOverview = getOverview(championOverviews[transformChampionId]?.critChampionOverview, params);
      const enableCrit = shouldEnableCrit(filteredOverview, filteredCritOverview, mythicItems, bootItems, isCrit);
      if (filteredCritOverview && filteredOverview && enableCrit) {
        tabData["crit"] = [
          filteredCritOverview?.rec_runes?.active_perks[0],
          filteredCritOverview?.rec_core_items?.items
            ? getClassifyItem(filteredCritOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredCritOverview.matches ? filteredCritOverview.matches : 0,
        ];
      }
      const filteredLethalityOverview = getOverview(championOverviews[transformChampionId]?.lethalityChampionOverview, params);
      const enableLethality = shouldEnableLethality(
        filteredOverview,
        filteredLethalityOverview,
        mythicItems,
        bootItems,
        isLethality,
      );
      if (filteredLethalityOverview && filteredOverview && enableLethality) {
        tabData["lethality"] = [
          filteredLethalityOverview?.rec_runes?.active_perks[0],
          filteredLethalityOverview?.rec_core_items?.items
            ? getClassifyItem(filteredLethalityOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredLethalityOverview.matches ? filteredLethalityOverview.matches : 0,
        ];
      }
      const filteredOnHitOverview = getOverview(championOverviews[transformChampionId]?.onHitChampionOverview, params);
      const enableOnHit = shouldEnableOnHit(filteredOverview, filteredOnHitOverview, mythicItems, bootItems, isOnHit);
      if (filteredOnHitOverview && filteredOverview && enableOnHit) {
        tabData["onhit"] = [
          filteredOnHitOverview?.rec_runes?.active_perks[0],
          filteredOnHitOverview?.rec_core_items?.items
            ? getClassifyItem(filteredOnHitOverview?.rec_core_items?.items, mythicItems, bootItems)
            : 0,
          filteredOnHitOverview.matches ? filteredOnHitOverview.matches : 0,
        ];
      }
      // Use this to test onhit
      // tabData["onhit"] = [
      //   filteredOnHitOverview?.rec_runes?.active_perks[0],
      //   filteredOnHitOverview?.rec_core_items?.items
      //     ? getClassifyItem(filteredOnHitOverview?.rec_core_items?.items, mythicItems, bootItems)
      //     : 0,
      //   100,
      // ];
    }

    if (Object.keys(tabData).length > 4) {
      let min = 100000;
      let minKey;
      for (const key in tabData) {
        const matches = tabData[key][2] ?? 0;
        if (matches < min) {
          min = matches;
          minKey = key;
        }
      }
      delete tabData[minKey];
    }

    return { buildKey, tabData };
  }, [championOverviews, ...tracking, JSON.stringify(params)]);

  return [tabData, params.patch];
}

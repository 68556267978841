import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";

function getRoleKey(region, rank) {
  return `${region}_${rank}`;
}

function getKey(region, rank, role) {
  let key = `${region}_${rank}_${role}`;
  return key;
}

function getValidRoles(data, params) {
  if (data && data[2]) {
    const { region, rank } = params;
    const validRoles = data[2];
    const roleKey = getRoleKey(region, rank);
    const retVal = validRoles[roleKey];
    return retVal;
  } else {
    return [];
  }
}

function cleanMatchups(data) {
  let cleanData = {};
  let availableRoles = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        const roleKey = getRoleKey(regionMap[regionId], rankMap[rankId]);
        if (!(roleKey in availableRoles)) {
          availableRoles[roleKey] = [];
        }
        availableRoles[roleKey].push(roleMap[roleId]);
        let totalMatches = 0;
        const counter = {
          counters: roleData[0].map((x) => {
            const matches = x[2];
            totalMatches += matches;

            return {
              champion_id: x[0],
              win_rate: _round((1 - x[1] / matches) * 100, 2),
              matches: matches,
              xp_adv_15: _round((x[3] / matches) * -1, 0),
              gold_adv_15: _round((x[4] / matches) * -1, 0),
              duo_gold_adv_15: _round((x[5] / matches) * -1, 0),
              cs_adv_15: _round((x[6] / matches) * -1, 1),
              duo_cs_adv_15: _round((x[7] / matches) * -1, 1),
              jungle_cs_adv_15: _round((x[8] / matches) * -1, 1),
              kill_adv_15: _round((x[9] / matches) * -1, 2),
              duo_kill_adv_15: _round((x[10] / matches) * -1, 2),
              duo_xp_adv_15: _round((x[11] / matches) * -1, 0),
              carry_percentage_15: _round((x[12] / matches) * -1 * 10, 0),
              duo_carry_percentage_15: _round((x[13] / matches) * -1 * 10, 0),
              team_gold_difference_15: _round((x[14] / matches) * -1, 0),
            };
          }),
          last_updated: roleData[1],
          matches: totalMatches,
        };

        counter.counters.forEach((counter) => {
          const pick_rate = _round((counter.matches / totalMatches) * 100, 2);
          counter["pick_rate"] = pick_rate;
          counter["tier"] = {
            pick_rate,
            win_rate: counter.win_rate,
          };
        });

        counter.counters.sort((a, b) => {
          if (a.pick_rate >= 0.5 && b.pick_rate >= 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          } else if (a.pick_rate >= 0.5 && b.pick_rate < 0.5) {
            return 1;
          } else if (a.pick_rate < 0.5 && b.pick_rate >= 0.5) {
            return -1;
          } else if (a.pick_rate < 0.5 && b.pick_rate < 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          }
          return 0;
        });

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = counter;
      }
    }
  }

  return { matchups: cleanData, roles: availableRoles };
}

function getMatchups(data, params) {
  const { region, rank, role } = params;
  if (!data) return null;

  return data[getKey(region, rank, getApiRole(role))];
}

export { cleanMatchups, getMatchups, getValidRoles };

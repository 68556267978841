import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";

// function getRoleKey(region, rank) {
//   return `${region}_${rank}`;
// }

function getKey(region, rank, role) {
  let key = `${region}_${rank}_${role}`;
  return key;
}

export function cleanDuosData(data) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        let [duos, last_updated] = roleData;

        let totalMatches = 0;

        duos = duos.map((duoChampion) => {
          const matches = duoChampion[2];
          totalMatches += matches;
          return {
            role: duoChampion[10],
            champion_id: duoChampion[0],
            champion_link: {
              champion_id: duoChampion[0],
              role: duoChampion[10],
            },
            wins: duoChampion[1],
            matches: duoChampion[2],
            win_rate: _round((duoChampion[1] / duoChampion[2]) * 100, 2),
            xp_adv_15: _round(duoChampion[3] / matches, 0),
            gold_adv_15: _round(duoChampion[4] / matches, 0),
            cs_adv_15: _round(duoChampion[5] / matches, 1),
            kill_adv_15: _round(duoChampion[6] / matches, 2),
            carry_percentage_15: _round((duoChampion[7] / matches) * 10, 0),
            duo_carry_percentage_15: _round((duoChampion[8] / matches) * 10, 0),
            team_gold_difference_15: _round(duoChampion[9] / matches),
          };
        });

        duos.forEach((duo) => {
          const pick_rate = _round((duo.matches / totalMatches) * 100, 2);
          duo["pick_rate"] = pick_rate;
          duo["tier"] = {
            pick_rate,
            win_rate: duo.win_rate,
          };
        });

        duos.sort((a, b) => {
          if (a.pick_rate >= 0.5 && b.pick_rate >= 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          } else if (a.pick_rate >= 0.5 && b.pick_rate < 0.5) {
            return 1;
          } else if (a.pick_rate < 0.5 && b.pick_rate >= 0.5) {
            return -1;
          } else if (a.pick_rate < 0.5 && b.pick_rate < 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          }
          return 0;
        });

        const championDuos = {
          duos,
          last_updated,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = championDuos;
      }
    }
  }

  return cleanData;
}

export function getDuos(data, params) {
  let { queueType, region, rank, role } = params;

  if (!data) return null;

  if (queueType === "normal_aram" || queueType == "nexus_blitz") {
    rank = "overall";
    role = "none";
  } else if (queueType === "normal_blind_5x5" || queueType === "normal_draft_5x5") {
    rank = "overall";
    role = getApiRole(role);
  } else {
    role = getApiRole(role);
  }

  return data[getKey(region, rank, role)];
}

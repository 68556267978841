import { getSpecialGameModeUggApiSettings } from "lib/specialGameMode";
import { getApiRole } from "lib/general-helper.js";

export function getKey(region, rank, role) {
  let key = `${region}_${rank}_${role}`;
  return key;
}

export function getFilteredData(data, params) {
  let { queueType, region, rank, role } = params;
  if (!data) return null;

  let specialGameModeUggApiSettings = getSpecialGameModeUggApiSettings(queueType);
  if (specialGameModeUggApiSettings) {
    if (!specialGameModeUggApiSettings.hasRank) {
      rank = "overall";
    }
    if (!specialGameModeUggApiSettings.hasRole) {
      role = "none";
    } else {
      role = getApiRole(role);
    }
  } else if (queueType === "normal_blind_5x5" || queueType === "normal_draft_5x5" || queueType === "quickplay") {
    rank = "overall";
    role = getApiRole(role);
  } else {
    role = getApiRole(role);
  }

  return data[getKey(region, rank, role)];
}

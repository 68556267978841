import { validParams } from "./valid_parameters";
import { global_exemptions } from "./valid_parameters";
import { useUGGPatches } from "lib/hooks/patchHooks";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

export function getValidParamKeys(page) {
  const validPageParams = validParams[page] || {};
  const validKeys = {};

  Object.keys(validPageParams).forEach((key) => {
    if (key === "exemptions") {
      validPageParams["exemptions"].forEach((exemption) => {
        validKeys[exemption] = true;
      });
    } else if (key !== "default") {
      validKeys[key] = true;
    }
  });

  return validKeys;
}

/**
 * @param {object} queryParams - Query parameters to validate
 * @param {string} page - Page type to get valid param options
 * @param {boolean} keepDefaultParams - Set to true if default params should be kept
 */
// Removes params with invalid keys or values
export function useValidateQueryParams() {
  const { useChampionMini } = getRiotAssetsContext();
  const { data: championMini } = useChampionMini();
  const uggPatches = useUGGPatches();

  return ({ queryParams, page, keepDefaultParams = false } = {}) => {
    let validParameters = validParams; // default champion stats params

    let validPageParams = validParameters[page] || {};
    let defaultParams = validPageParams.default || {};
    let filters = Object.assign({}, defaultParams, queryParams);
    let validatedParams = keepDefaultParams ? { ...filters } : { ...queryParams };

    const currentParams = Object.keys(validatedParams);
    currentParams.forEach((key) => {
      const paramValue = validatedParams[key];
      // Remove if default param value
      if (!keepDefaultParams && defaultParams[key] === paramValue) {
        delete validatedParams[key];
      } else if (validPageParams[key] === undefined) {
        // Check any exemptions
        const checkGlobalExemption = global_exemptions.includes(key);
        const checkPageExemption = validPageParams["exemptions"] ? validPageParams["exemptions"].includes(key) : false;
        if (key === "opp" || key === "champion") {
          const isValidValue = Object.values(championMini || {}).find((champion) => {
            return (
              champion.id.toLowerCase() === paramValue.toLowerCase() || champion.name.toLowerCase() === paramValue.toLowerCase()
            );
          });

          if (!isValidValue) {
            delete validatedParams[key];
          }
        }
        if (checkGlobalExemption === false && checkPageExemption === false) {
          delete validatedParams[key];
        }
      } else {
        let isValidValue = true;
        if (Array.isArray(validPageParams[key])) {
          isValidValue = validPageParams[key].find((option) => {
            return option.value === paramValue;
          });
        }
        // Remove if not valid param value
        if (!isValidValue) {
          delete validatedParams[key];
        }
      }
    });

    const { data } = uggPatches;
    const { patch: defaultPatch } = defaultParams || {};
    const { options, max } = validPageParams.patch || {};
    const maxUGGPatches = data.slice(0, max);
    // Delete if default patch
    if (
      !keepDefaultParams &&
      ((defaultPatch === "latest" && maxUGGPatches[0] === validatedParams.patch) ||
        (defaultPatch !== "latest" && defaultPatch === validatedParams.patch))
    ) {
      delete validatedParams.patch;
    }
    // Delete if invalid patch
    if (
      validatedParams.patch &&
      ((options === "latest" && !maxUGGPatches.includes(validatedParams.patch)) ||
        (Array.isArray(options) && !options.includes(validatedParams.patch)))
    ) {
      delete validatedParams.patch;
    }
    // Replace "latest" with valid patch value
    if (keepDefaultParams && defaultPatch === "latest" && !validatedParams.patch) {
      validatedParams.patch = maxUGGPatches[0];
    }

    return validatedParams;
  };
}

import { getApiRoot } from "./apiHelpers";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useUGGPatches } from "lib/hooks/patchHooks";
import { useUGGApiVersions } from "api/uggApiVersions";

// Primary Roles
export function useChampionRolesURL() {
  const patches = useUGGPatches();
  const versions = useUGGApiVersions();

  if (patches.data && versions.data) {
    const patch = patches.data[0];
    return `${getApiRoot()}/primary_roles/${patch}/${versions.data[patch].primary_roles}.json`;
  } else {
    return null;
  }
}

// Champion Ranking Stats
export function useChampionRankingStatsURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "rankings", patch: options.patch });

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const root = getApiRoot(options.patch);
  let rankText = "rankings";
  if (championId > 10000) {
    rankText = "ct-rankings";
  }
  return [`${root}/${rankText}`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Overview
export function useChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");

  return path;
}

// Champion AP Overview
export function useAPChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/ap-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion Tank Overview
export function useTankChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/tank-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion AD Overview
export function useADChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/ad-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion Crit Overview
export function useCritChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/crit-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion Lethality Overview
export function useLethalityChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/lethality-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion OnHit Overview
export function useOnHitChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/onhit-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

export function useCTChampionOverviewURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "overview", patch: options.patch });

  if (options.queueType !== "ranked_solo_5x5" && options.queueType !== "ranked_flex_sr") {
    delete options.opp;
  }

  // backend saves to /urf/ url instead of /arurf/
  if (options.queueType === "arurf") {
    options.queueType = "urf";
  }

  const championIdParam = !options.opp ? championId : `matchups/${championId}_${options.opp}`;
  const root = getApiRoot(options.patch);

  const path = [`${root}/ct-overview`, options.patch, options.queueType, championIdParam, `${version}.json`].join("/");
  //console.log(path)
  return path;
}

// Champion Rec Build
export function useChampionRecBuildURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "builds", patch: options.patch });
  const root = getApiRoot(options.patch);

  return [`${root}/builds`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Duos
export function useChampionDuosURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "champion_duos", patch: options.patch });
  const root = getApiRoot(options.patch);

  return [`${root}/champion_duos`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Items
export function useItemsURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "items", patch: options.patch });
  const root = getApiRoot(options.patch);

  return [`${root}/table/items`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Runes
export function useRunesURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "runes", patch: options.patch });
  const root = getApiRoot(options.patch);

  return [`${root}/table/runes`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Matchups (table)
export function useMatchupsURL(championId, options) {
  const { data: version } = useUGGApiVersions({ apiKey: "matchups", patch: options.patch });
  const root = getApiRoot(options.patch);

  return [`${root}/matchups`, options.patch, options.queueType, championId, `${version}.json`].join("/");
}

// Champion Counters (Currently not being used)
// export function useCountersURL(championId, options) {
//   const { data: version } = useUGGApiVersions({apiKey: "counters", patch: options.patch});
//   const root = getApiRoot(options.patch);
//
//   return [
//     `${root}/counters`,
//     options.patch,
//     options.queueType,
//     championId,
//     `${version}.json`
//   ].join("/");
// }

/******************************
 * Non Champion Profile Hooks
 ******************************/

// Homepage Pro Builds
export function useProHomepageURL() {
  const patches = useUGGPatches();
  const versions = useUGGApiVersions();

  if (patches.data && versions.data) {
    const patch = patches.data[0];
    return `${getApiRoot()}/homepage/pro_data/${versions.data[patch].pro_homepage}.json`;
  } else {
    return null;
  }
}

import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";
import { getSpecialGameModeUggApiSettings } from "lib/specialGameMode";
import { getKey, getFilteredData } from "./dataParserHelpers";

export function cleanOverviewData(data) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        // if(regionId == 12 && rankId == 10 && roleId == 5)
        //   console.log(roleData)
        let [
          rec_runes,
          rec_summoner_spells,
          rec_starting_items,
          rec_core_items,
          // rec_skills,
          rec_skill_path,
          rec_item_options,
          champion_matches,
          build_warning,
          stat_shards,
          rec_skill_evolve_path,
          arenaSynergies,
          arenaAugments,
        ] = roleData[0];

        stat_shards =
          Array.isArray(stat_shards) && stat_shards.length > 0
            ? {
                matches: stat_shards[0],
                wins: stat_shards[1],
                win_rate: _round((stat_shards[1] / stat_shards[0]) * 100, 2),
                activeShards: stat_shards[2],
              }
            : null;

        const last_updated = roleData[1];

        rec_runes =
          rec_runes.length !== 0
            ? {
                matches: rec_runes[0],
                wins: rec_runes[1],
                win_rate: _round((rec_runes[1] / rec_runes[0]) * 100, 2),
                primary_style: rec_runes[2],
                sub_style: rec_runes[3],
                active_perks: rec_runes[4],
              }
            : null;

        [
          rec_summoner_spells,
          rec_starting_items,
          rec_core_items,
          // rec_skills,
          // rec_skill_path,
        ] = [
          rec_summoner_spells,
          rec_starting_items,
          rec_core_items,
          // rec_skills,
          // rec_skill_path,
        ].map((section) => {
          if (!section || section.length === 0) return null;

          return {
            matches: section[0],
            wins: section[1],
            win_rate: _round((section[1] / section[0]) * 100, 2),
            items: section[2],
          };
        });

        const skill_priority = rec_skill_path && rec_skill_path[3] ? rec_skill_path[3].split("") : [];
        const rec_skills = {
          items: skill_priority,
        };

        rec_skill_path =
          !rec_skill_path || rec_skill_path.length === 0
            ? {}
            : {
                matches: rec_skill_path[0],
                wins: rec_skill_path[1],
                win_rate: _round((rec_skill_path[1] / rec_skill_path[0]) * 100, 2),
                items: rec_skill_path[2],
              };

        rec_skill_evolve_path =
          !rec_skill_evolve_path || rec_skill_evolve_path.length === 0
            ? {}
            : {
                matches: rec_skill_evolve_path[0],
                wins: rec_skill_evolve_path[1],
                win_rate: _round((rec_skill_evolve_path[1] / rec_skill_evolve_path[0]) * 100, 2),
                items: rec_skill_evolve_path[2].split(""),
              };

        let [item_options_1, item_options_2, item_options_3, consumable_options, prismatic_options] = rec_item_options;

        [item_options_1, item_options_2, item_options_3, consumable_options] = [
          item_options_1,
          item_options_2,
          item_options_3,
          consumable_options,
        ].map((options) => {
          return (options || []).map((item) => {
            return {
              wins: item[1],
              matches: item[2],
              win_rate: _round((item[1] / item[2]) * 100, 2),
              item_id: item[0],
            };
          });
        });

        const wins = champion_matches[0];
        const matches = champion_matches[1];
        const win_rate = wins >= 0 && matches > 0 ? _round((wins / matches) * 100, 2) : null;

        prismatic_options = (prismatic_options || []).map((item) => {
          return {
            wins: item[3],
            matches: item[4],
            avgPlacement: _round(item[2] / item[4], 2),
            top4Rate: _round((item[3] / item[4]) * 100, 2),
            id: item[0],
            firstRate: _round((item[1] / item[4]) * 100, 2),
            pickRate: _round(((item[4] || 0) / matches) * 100, 2),
          };
        });

        // Arena Synergies
        const arena_synergies = (arenaSynergies || []).map((champion) => {
          return {
            championId: champion[0],
            avgPlacement: _round((champion[4] || 0) / champion[2], 2),
            firstRate: _round(((champion[3] || 0) / champion[2]) * 100, 2),
            top2Rate: _round(((champion[1] || 0) / champion[2]) * 100, 2),
            pickRate: _round(((champion[2] || 0) / matches) * 100, 2),
            matches: champion[2],
          };
        });

        // Arena Augments
        const arena_augments = (arenaAugments || []).map((augment) => {
          return {
            augmentId: augment[0],
            pickRate: _round((augment[2] / matches) * 100, 2),
            matches: augment[2],
          };
        });

        const overview = {
          stat_shards,
          rec_runes,
          rec_summoner_spells,
          rec_starting_items,
          rec_core_items,
          rec_skills,
          rec_skill_path,
          rec_skill_evolve_path,
          item_options_1,
          item_options_2,
          item_options_3,
          prismatic_options,
          consumable_options,
          wins,
          matches,
          win_rate,
          build_warning,
          last_updated,
          arena_synergies,
          arena_augments,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = overview;
      }
    }
  }

  return cleanData;
}

export function cleanOverviewData2(data) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        let [keystone_pairs, summoner_spells, starting_items, core_items_with_boots, skills, boots, core_items] = roleData;

        keystone_pairs = keystone_pairs.map((x) => {
          const runes = x[5].map((tree) => {
            return {
              primary_style: tree[0],
              sub_style: tree[1],
              wins: tree[3],
              matches: tree[4],
              win_rate: _round((tree[3] / tree[4]) * 100, 2),
              active_perks: tree[2],
            };
          });

          return {
            keystone_id: x[0],
            primary_style: x[4],
            sub_style: x[1],
            wins: x[2],
            matches: x[3],
            win_rate: _round((x[2] / x[3]) * 100, 2),
            runes: runes,
          };
        });

        [summoner_spells, starting_items, core_items, skills, boots, core_items_with_boots] = [
          summoner_spells,
          starting_items,
          core_items,
          skills,
          boots,
          core_items_with_boots,
        ].map((section) => {
          return section.map((data) => {
            if (data.length === 0) return null;

            return {
              matches: data[2],
              wins: data[1],
              win_rate: _round((data[1] / data[2]) * 100, 2),
              items: Array.isArray(data[0]) ? data[0] : [data[0]],
            };
          });
        });

        const overview = {
          keystone_pairs,
          summoner_spells,
          starting_items,
          core_items,
          skills,
          boots,
          core_items_with_boots,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = overview;
      }
    }
  }

  return cleanData;
}

export function getOverview(data, params) {
  return getFilteredData(data, params);
}

import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";
import { getFilteredData, getKey } from "./dataParserHelpers";

function calculateKDA(kills, deaths, assists) {
  if (isNaN(kills) || isNaN(assists) || isNaN(deaths)) {
    return null;
  } else if (kills === 0 && assists === 0 && deaths === 0) {
    return "0.00";
  } else if (deaths === 0) {
    return "Perfect";
  } else {
    return _round((kills + assists) / deaths, 2).toFixed(2);
  }
}

export function cleanRankingsData(data) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        let [
          wins,
          matches,
          rank,
          total_rank,
          damage,
          gold,
          kills,
          assists,
          deaths,
          cs,
          bans,
          total_matches,
          counters,
          real_matches,
          stdevs,
          effective_winrate,
          distribution_count,
          distribution_mean,
          distribution_stdevs,
          be_all_picks,
        ] = roleData;

        counters = counters.map((counter) => {
          const matches = counter[2];

          return {
            champion_id: counter[0],
            wins: counter[1],
            matches,
            win_rate: _round((counter[1] / matches) * 100, 2),
          };
        });

        const rankingsData = {
          wins,
          matches,
          rank,
          total_rank,
          avg_damage: damage / matches,
          avg_gold: gold / matches,
          // kills,
          // assists,
          // deaths,
          avg_kda: calculateKDA(kills, deaths, assists),
          avg_cs: _round(cs / matches, 0),
          win_rate: _round((wins / matches) * 100, 2),
          pick_rate: _round((matches / total_matches) * 100, 1),
          ban_rate:
            real_matches !== 0 && real_matches !== null && real_matches !== undefined
              ? _round((bans / real_matches) * 100, 1)
              : _round((bans / total_matches) * 100, 1),
          total_matches,
          counters,
          stdevs,
          effective_winrate,
          matches,
          bans,
          real_matches,
          total_matches,
          distribution_count,
          distribution_mean,
          distribution_stdevs,
          be_all_picks,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = rankingsData;
      }
    }
  }
  return cleanData;
}

export function getRankings(data, params) {
  return getFilteredData(data, params);
}

const regionMap = {
  1: "na1",
  2: "euw1",
  3: "kr",
  4: "eun1",
  5: "br1",
  6: "la1",
  7: "la2",
  8: "oc1",
  9: "ru",
  10: "tr1",
  11: "jp1",
  12: "world",
  13: "ph2",
  14: "sg2",
  15: "th2",
  16: "tw2",
  17: "vn2",
  18: "me1",
};

const rankMap = {
  1: "challenger",
  2: "master",
  3: "diamond",
  4: "platinum",
  5: "gold",
  6: "silver",
  7: "bronze",
  8: "overall",
  9: "unknown",
  10: "platinum_plus",
  11: "diamond_plus",
  12: "iron",
  13: "grandmaster",
  14: "master_plus",
  15: "diamond_2_plus",
  16: "emerald",
  17: "emerald_plus",
};

const roleMap = {
  1: "jungle",
  2: "supp",
  3: "adc",
  4: "top",
  5: "mid",
  6: "none",
  8: "lane",
};

export { regionMap, roleMap, rankMap };

import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";

// function getRoleKey(region, rank) {
//   return `${region}_${rank}`;
// }

function getKey(region, rank, role) {
  let key = `${region}_${rank}_${role}`;
  return key;
}

export function cleanItems(data) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        // console.log(roleData)
        const [starting_items, items_1, boots, items_2, items_3, items_4, items_5] = roleData.map((set) => {
          let totalMatches = 0;
          set.forEach((item) => (totalMatches += item[2]));

          return set.map((item) => {
            const wins = item[1];
            const matches = item[2];

            return {
              item_id: Array.isArray(item[0]) ? item[0] : [item[0]],
              wins: item[1],
              matches: item[2],
              win_rate: _round((wins / matches) * 100, 2),
              pick_rate: _round((matches / totalMatches) * 100, 2),
            };
          });
        });

        const items = {
          starting_items,
          items_1,
          boots,
          items_2,
          items_3,
          items_4,
          items_5,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = items;
      }
    }
  }

  return cleanData;
}

export function getItems(data, params) {
  let { queueType, region, rank, role } = params;
  if (!data) return null;

  if (queueType === "normal_aram" || queueType == "nexus_blitz") {
    rank = "overall";
    role = "none";
  } else if (queueType === "normal_blind_5x5" || queueType === "normal_draft_5x5") {
    rank = "overall";
    role = getApiRole(role);
  } else {
    role = getApiRole(role);
  }

  return data[getKey(region, rank, role)];
}

import {
  TIER_LIST_OPTIONS,
  TIER_LIST_DEFAULT,
  DUO_TIER_LIST_OPTIONS,
  DUO_TIER_LIST_DEFAULT,
  COMBAT_OPTIONS,
  COMBAT_DEFAULT,
  OBJECTIVES_OPTIONS,
  OBJECTIVES_DEFAULT,
  OVERVIEW_OPTIONS,
  OVERVIEW_DEFAULT,
  ADV_BUILD_OPTIONS,
  ADV_BUILD_DEFAULT,
  ITEMS_OPTIONS,
  ITEMS_DEFAULT,
  RUNES_OPTIONS,
  RUNES_DEFAULT,
  MATCHUPS_OPTIONS,
  MATCHUPS_DEFAULT,
  COUNTERS_OPTIONS,
  COUNTERS_DEFAULT,
  DUOS_OPTIONS,
  DUOS_DEFAULT,
  WORLDS_PROBUILDS_OPTIONS,
  WORLDS_PROBUILDS_DEFAULT,
  TOGGLE_OPTIONS,
  NEXUS_BLITZ_ROLE_OPTIONS,
  ALL_NEXUS_BLITZ_ROLE_OPTIONS,
} from "./filter_options";

export const validParams = {
  "tier-list": {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    rank: TIER_LIST_OPTIONS.rank,
    role: TIER_LIST_OPTIONS.role,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: TIER_LIST_DEFAULT,
  },
  "duo-tier-list": {
    region: DUO_TIER_LIST_OPTIONS.region,
    queueType: DUO_TIER_LIST_OPTIONS.queueType,
    patch: DUO_TIER_LIST_OPTIONS.patch,
    rank: DUO_TIER_LIST_OPTIONS.rank,
    duo: DUO_TIER_LIST_OPTIONS.duo,
    default: DUO_TIER_LIST_DEFAULT,
  },
  combat: {
    region: COMBAT_OPTIONS.region,
    queueType: COMBAT_OPTIONS.queueType,
    patch: COMBAT_OPTIONS.patch,
    rank: COMBAT_OPTIONS.rank,
    role: COMBAT_OPTIONS.role,
    allChampions: COMBAT_OPTIONS.allChampions,
    default: COMBAT_DEFAULT,
  },
  objectives: {
    region: OBJECTIVES_OPTIONS.region,
    queueType: OBJECTIVES_OPTIONS.queueType,
    patch: OBJECTIVES_OPTIONS.patch,
    rank: OBJECTIVES_OPTIONS.rank,
    role: OBJECTIVES_OPTIONS.role,
    allChampions: OBJECTIVES_OPTIONS.allChampions,
    default: OBJECTIVES_DEFAULT,
  },
  "nexus-blitz-tier-list": {
    region: TIER_LIST_OPTIONS.region,
    queueType: TIER_LIST_OPTIONS.queueType,
    patch: TIER_LIST_OPTIONS.patch,
    role: ALL_NEXUS_BLITZ_ROLE_OPTIONS,
    allChampions: TIER_LIST_OPTIONS.allChampions,
    default: TIER_LIST_DEFAULT,
  },
  "arena-tier-list": {
    region: TIER_LIST_OPTIONS.region,
    patch: TIER_LIST_OPTIONS.patch,
    default: TIER_LIST_DEFAULT,
  },
  "arena-duo-tier-list": {
    region: TIER_LIST_OPTIONS.region,
    patch: TIER_LIST_OPTIONS.patch,
    default: TIER_LIST_DEFAULT,
  },
  build: {
    region: OVERVIEW_OPTIONS.region,
    queueType: OVERVIEW_OPTIONS.queueType,
    rank: OVERVIEW_OPTIONS.rank,
    role: OVERVIEW_OPTIONS.role,
    exemptions: ["opp"],
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  runes: {
    region: OVERVIEW_OPTIONS.region,
    queueType: OVERVIEW_OPTIONS.queueType,
    rank: OVERVIEW_OPTIONS.rank,
    role: OVERVIEW_OPTIONS.role,
    exemptions: ["opp"],
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  overview_aram: {
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  overview_arurf: {
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  overview_urf: {
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: OVERVIEW_DEFAULT,
  },
  overview_one_for_all: {
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    role: OVERVIEW_OPTIONS.role,
    default: OVERVIEW_DEFAULT,
  },
  overview_nexus_blitz: {
    role: NEXUS_BLITZ_ROLE_OPTIONS,
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: { ...OVERVIEW_DEFAULT, queueType: "nexus_blitz" },
    exemptions: ["queueType"],
  },
  overview_arena: {
    region: OVERVIEW_OPTIONS.region,
    patch: OVERVIEW_OPTIONS.patch,
    default: { ...OVERVIEW_DEFAULT, queueType: "arena" },
    exemptions: ["queueType"],
  },
  "item-paths": {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  items: {
    region: ITEMS_OPTIONS.region,
    queueType: ITEMS_OPTIONS.queueType,
    patch: ITEMS_OPTIONS.patch,
    rank: ITEMS_OPTIONS.rank,
    role: ITEMS_OPTIONS.role,
    default: ITEMS_DEFAULT,
  },
  "spells-abilities": {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  "rune-sets": {
    region: ADV_BUILD_OPTIONS.region,
    queueType: ADV_BUILD_OPTIONS.queueType,
    patch: ADV_BUILD_OPTIONS.patch,
    rank: ADV_BUILD_OPTIONS.rank,
    role: ADV_BUILD_OPTIONS.role,
    default: ADV_BUILD_DEFAULT,
  },
  "runes-table": {
    region: RUNES_OPTIONS.region,
    queueType: RUNES_OPTIONS.queueType,
    patch: RUNES_OPTIONS.patch,
    rank: RUNES_OPTIONS.rank,
    role: RUNES_OPTIONS.role,
    default: RUNES_DEFAULT,
  },
  matchups: {
    region: MATCHUPS_OPTIONS.region,
    queueType: MATCHUPS_OPTIONS.queueType,
    patch: MATCHUPS_OPTIONS.patch,
    rank: MATCHUPS_OPTIONS.rank,
    role: MATCHUPS_OPTIONS.role,
    allChampions: MATCHUPS_OPTIONS.allChampions,
    default: MATCHUPS_DEFAULT,
  },
  counters: {
    region: COUNTERS_OPTIONS.region,
    queueType: COUNTERS_OPTIONS.queueType,
    patch: COUNTERS_OPTIONS.patch,
    rank: COUNTERS_OPTIONS.rank,
    role: COUNTERS_OPTIONS.role,
    default: COUNTERS_DEFAULT,
  },
  duos: {
    region: DUOS_OPTIONS.region,
    queueType: DUOS_OPTIONS.queueType,
    patch: DUOS_OPTIONS.patch,
    rank: DUOS_OPTIONS.rank,
    role: DUOS_OPTIONS.role,
    allChampions: DUOS_OPTIONS.allChampions,
    default: DUOS_DEFAULT,
  },
  worlds: {
    role: WORLDS_PROBUILDS_OPTIONS.role,
    league: WORLDS_PROBUILDS_OPTIONS.league,
    league2: WORLDS_PROBUILDS_OPTIONS.league,
    default: WORLDS_PROBUILDS_DEFAULT,
  },
};

// https://u.gg/lol/champions/caitlyn/build?google_preview=XNvy0N2m7PgY6v-yhwYw6pv[…]020501169&gdfp_req=1&lineItemId=5739269658&creativeId=138356381980

export const global_exemptions = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "url",
  "gclid",
  "gtm",
  "google_preview",
  "gdfp_req",
  "lineItemId",
  "creativeId",
  "googfc",
  "iframe",
  "aps_debug",
  "aps_preview",
  "aps_pause",
  // for lngtd. Remove after they are done
  "krg_qa",
  "ad_id",
  "google_preview",
  "iu",
  "gdfp_req",
  "lineItemId",
  "creativeId",
  "testbidder",
];

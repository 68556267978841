import { regionMap, roleMap, rankMap } from "./filter_maps.js";
import _round from "lodash.round";
import { getApiRole } from "lib/general-helper.js";

function getKey(region, rank, role) {
  let key = `${region}_${rank}_${role}`;
  return key;
}

export function cleanRunes(data, options) {
  let cleanData = {};
  for (const [regionId, regionData] of Object.entries(data)) {
    for (const [rankId, rankData] of Object.entries(regionData)) {
      for (const [roleId, roleData] of Object.entries(rankData)) {
        let [keystone, primary, secondary] = roleData;

        const matchSum = (runes, div = 1) => {
          let totalMatches = 0;
          for (const [runeId, runeData] of Object.entries(runes)) {
            totalMatches += runeData[1];
          }

          return Math.trunc(totalMatches / div);
        };

        const keystoneMatches = matchSum(keystone, 1);
        const primaryMatches = matchSum(primary, 3);
        const secondaryMatches = matchSum(secondary, 2);

        const totalMatches = Math.max(keystoneMatches, primaryMatches, secondaryMatches);

        [keystone, primary, secondary] = roleData.map((set, index) => {
          for (const [runeId, runeData] of Object.entries(set)) {
            const wins = runeData[0];
            const matches = runeData[1];

            set[runeId] = {
              wins,
              matches,
              win_rate: _round((wins / matches) * 100, 2),
              pick_rate: _round((matches / totalMatches) * 100, 2),
            };
          }

          return set;
        });

        const runes = {
          keystone,
          primary,
          secondary,
        };

        const key = getKey(regionMap[regionId], rankMap[rankId], roleMap[roleId]);
        cleanData[key] = runes;
      }
    }
  }

  return cleanData;
}

export function getRunes(data, params) {
  let { queueType, region, rank, role } = params;
  if (!data) return null;

  if (queueType === "normal_aram" || queueType == "nexus_blitz") {
    rank = "overall";
    role = "none";
  } else if (queueType === "normal_blind_5x5" || queueType === "normal_draft_5x5") {
    rank = "overall";
    role = getApiRole(role);
  } else {
    role = getApiRole(role);
  }

  return data[getKey(region, rank, role)];
}
